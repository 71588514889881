<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="类型" prop="type">
          <el-select v-model="formInline.type"  clearable filterable  >
            <el-option v-for="(item, index) of dictList" :key="index" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称(或编码)" prop="name">
          <el-input v-model="formInline.name" placeholder="请输入名称(或编码)"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-button type="primary" icon="el-icon-plus" @click="addData()">新增</el-button>
      <el-table style="width:100%" border :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" :data="resultList">
        <!-- <el-table-column type="selection"  width="45" fixed="left"></el-table-column> -->
        <el-table-column label="类型" prop="classify" width="275" maxlength="50">
          <template slot-scope="{row}">
            <span>{{row.classify}}</span>
          </template>
        </el-table-column>
        <el-table-column label="编码" prop="code" width="270" maxlength="50">
          <template slot-scope="{row}">
            <span>{{row.code}}</span>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" width="270">
          <template slot-scope="{row}">
            <span>{{row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort" width="250">
          <template slot-scope="{row}">
            <span>{{row.sort}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否启用" class="state" width="250" prop="useFlag">
          <template slot-scope="{row}">
            <span v-if="row.useFlag === '1'" style="color:#009933">启用</span>
            <span v-if="row.useFlag === '0'" style="color:#cc0000">禁用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="290">
          <template slot-scope="{row}">
            <el-button @click="handleEdit(row)" type="primary" size="small">编辑</el-button>
            <el-button v-if="row.useFlag === '0'" @click="handleDelete(row, row.id)" type="primary" style="background:#ff5722"  size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pagesize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate" :close-on-click-modal="false">
        <el-form :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="类型" prop="classify" :label-width="formLabelWidth">
            <el-input v-show="classifyStatus === 'add'" v-model="temp.classify"  @input="e => temp.classify= validForbid(e)" style="width:60%" placeholder="请输入类型"  show-word-limit maxlength="50"></el-input>
            <el-select  v-show="dialogStatus === 'addpage' && classifyStatus !== 'add'" clearable filterable ref="select" @focus="focus" @visible-change="visibleChange" v-model="temp.classify" style="width:60%">
              <el-option v-for="(item, index) of dictList"  :key="index" :label="item" :value="item" ></el-option>
            </el-select>
             <el-select  v-show="dialogStatus === 'update' && classifyStatus !== 'add'" :disabled="true" clearable filterable  v-model="temp.classify" style="width:60%">
              <el-option v-for="(item, index) of dictList" :key="index" :label="item" :value="item" ></el-option>
            </el-select>
              <el-button  v-show="dialogStatus === 'addpage' && classifyStatus !== 'add'" style="width:20%" type="primary" @click="addClassify">
               添加分类
            </el-button>
          </el-form-item>
          <el-form-item label="编码" prop="code" :label-width="formLabelWidth">
            <el-input v-model="temp.code" placeholder="请输入编码"  @input="e => temp.code= validForbid(e)" show-word-limit maxlength="50" style="padding:0 48px 0 0"></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name" placeholder="请输入名称"  @input="e => temp.name= validForbid(e)" show-word-limit maxlength="50" style="padding:0 48px 0 0"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
            <el-input type="number" v-model="temp.sort" :min="1" :max="9999"  @input="e => temp.sort=parserNumber(e,1,9999)" style="padding:0 48px 0 0"></el-input>
          </el-form-item>
          <el-form-item label="是否启用" prop="useFlag" :label-width="formLabelWidth">
            <el-switch v-model="whether"></el-switch>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="dialogStatus === 'addpage' ? addClick('temp') : updateData('temp')">
              立即提交
            </el-button>
            <el-button @click="resetForm('temp')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { dictData, dictType, dictSearch, dictAdd, dictEdit, dictDel } from '@/api/api.js'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
      formInline: {
        name: '',
        type: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      classifyStatus: '',
      textMap: {
        update: '编辑字典',
        addpage: '新增字典'
      },
      dictList: [],
      temp: {
        id: '',
        classify: '',
        code: '',
        name: '',
        sort: 1,
        useFlag: ''
      },
      resultList: [],
      pagesize: 10,
      currentPage: 1,
      total: 0,
      whether: false,
      inputFlag: null,
      formLabelWidth: '100px',
      rules: {
        classify: [{ required: true, message: '请输入类型', trigger: 'blur' }],
        code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.name,
        classify: this.formInline.type
      }
      dictData(params).then(res=>{
      
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 类型数据
    getDictList () {
      var _this = this
      dictType().then(res => {
        _this.dictList = res.data.data
      })
    },
    refreshData () {
      this.dataList()
      this.getDictList()
    },
    focus(){
       if(this.inputFlag){
         this.$refs.select.blur()
       }
    },
    visibleChange(flag){
        setTimeout(() =>{
          this.inputFlag=flag
        },0)
    },
    // 搜索
    onSubmit () {
      this.currentPage=1
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.name,
        classify: this.formInline.type
      }
      dictSearch(params).then(res=>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
    }, 
    // 搜索重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.refreshData()
    },
    resetTemp () {
      this.temp = {
        classify: '',
        code: '',
        name: '',
        sort: 1,
        useFlag: 0
      }
      this.whether = false
    },
    whetherStates () {
      if (this.whether === false) {
        this.temp.useFlag = 0
      } else {
        this.temp.useFlag = 1
      }
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
    },
    // 增加
    addData () {
      this.resetTemp()
      this.dialogStatus = 'addpage'
      this.classifyStatus=''
      this.whether = true
      this.dialogFormVisible = true
      this.whetherStates()
    },
    // 增加分类
    addClassify () {
      this.classifyStatus = 'add'
      
    },
    addClick (temp) {
      
      this.$refs[temp].validate((valid) => {
        if (valid) {
           this.whetherStates()
          var params = {
            'classify': this.temp.classify,
            'code': this.temp.code,
            'name': this.temp.name,
            'useFlag': Number(this.temp.useFlag),
            'sort': this.temp.sort
          }
          dictAdd(params).then(res=>{
            if (res.data.code === 100) {
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.refreshData()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if(err !== null && err !=='' && err.responseText !== null){
              this.$message.error('提交失败,请重试')
            }
          })
        }else{
          this.$message.error('完善信息')
        }
      })
    },
    // 编辑
    handleEdit (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.dialogStatus = 'update'
      this.classifyStatus=''
      this.dialogFormVisible = true
      if (this.temp.useFlag === '0') {
        this.whether = false
      } else {
        this.whether = true
      }
    },
    updateData (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          this.whetherStates()
          var params = {
            id: this.temp.id,
            classify: this.temp.classify,
            code: this.temp.code,
            name: this.temp.name,
            sort: this.temp.sort,
            useFlag: this.temp.useFlag
          }
          dictEdit(params).then(res=>{
            if(res.data.code === 100){
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.refreshData()
              this.dialogFormVisible = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    // 重置
    resetForm (temp) {
      // if (this.$refs[temp].resetFields() !== undefined) {
      // this.$refs[temp].resetFields()
      // }
     
      if(this.dialogStatus=='addpage'){
        this.classifyStatus=''
        this.temp.classify=''
        this.temp.code=''
        this.temp.name=''
        this.temp.sort=1
        this.useFlag=1
        this.whether = true
      }else{
        this.temp.code=''
        this.temp.name=''
        this.temp.sort=1
        this.useFlag=1
        this.whether = true
      }
     
    },
    // 删除
    handleDelete (row, id) {
      this.$confirm('确定删除 ' + row.name + '?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        dictDel(id).then(res =>{
          if (res.data.code === 100) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            if(this.resultList!=null&&this.resultList.length==1){
              this.currentPage =this.currentPage-1
            }
            this.refreshData()
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }).catch((error) => {
        this.$message.error('删除失败')
      })
    }
  },
  mounted () {
    var _this = this
    _this.dataList()
    _this.getDictList()
  }
}
</script>

